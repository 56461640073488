.crm-fields-selector {
  border-radius: var(--standard-border-radius);
  background-color: var(--page-back);

  &__no-background {
    background-color: unset;
    border: var(--standard-border);
  }

  &:not(&:has(&__header)) &__body {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x3) var(--spacing-unit-x2) var(--spacing-unit-x3);
  }

  &__header {
    display: flex;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x4);
    justify-content: space-between;
    align-items: center;

    &-title {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }

    &-action {
      width: var(--spacing-unit-x6);
      height: var(--spacing-unit-x6);
    }
  }

  &__body {
    padding: var(--spacing-unit) var(--spacing-unit-x4) var(--spacing-unit-x2) var(--spacing-unit-x4);

    &-fields-container {
      display: flex;
      flex-direction: column;
      row-gap: var(--spacing-unit-x3);
    }

    &-fields-container + &-button-container {
      margin-top: var(--spacing-unit-x4);
    }

    &-button-container {
      padding-top: var(--spacing-unit-x2);
      border-top: var(--standard-border-dark);
    }
  }

  &__dropdown {
    .drop-down__drop-down {
      padding-top: 0;
    }
    
    &-actions {
      max-height: 25rem;
      overflow-y: auto;
    }

    &-input {
      &-container {
        width: 25rem;
        padding: var(--spacing-unit-x2);
        border-bottom: var(--standard-border);
      }
    }
  }

  &__field {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-unit-x2);
    
    &:has(&-date),
    &:has(&-checkbox) {
      align-items: center;
    }

    &:has(&-input),
    &:has(&-select) {
      >.tooltip__trigger {
        margin-top: calc(26rem / 12);
      }
    }

    >:first-child {
      flex: 1;
    }
  }

  &__field-remove-button,
  &__remove-all-button {
    &:hover {
      background-color: var(--status-warning);

      > svg {
        fill: var(--remove-red) !important;
      }
    }
  }

  &__field-remove-button {
    border-radius: var(--extra-large-border-radius);
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);
  }
}