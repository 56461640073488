.email-composer-header {
  display: flex;
  gap: var(--spacing-unit-x8);
  padding: var(--spacing-unit-x4);
  position: sticky;
  top: 0;
  background-color: var(--white);
  z-index: calc(var(--z-index-composer-tablist) + 1);
  max-height: 7.2rem;
  width: 100%;
  height: var(--spacing-unit-x14);

  &__title-no-edit {
    font-weight: var(--font-weight-bold);
    padding: var(--half-spacing-unit) var(--spacing-unit-x2);
  }

  &__remove-padding {
    padding: 0;
  }

  &__customer-care {
    margin: 0;
    position: sticky;
    top: 0;
    z-index: calc(var(--z-index-composer-tablist) + 1);
  }

  &__back-button-svg {
    margin-right: 0;
  }

  &__nav {
    gap: var(--spacing-unit-x4);
    flex: 1;
  }

  &__right {
    gap: var(--spacing-unit-x4);
  }

  &__save-close {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
  }

  &__save-status {
    display: flex;
    align-items: center;
    gap: calc(var(--spacing-unit-x5) / 3);
    text-align: left;
  }

  &__status {
    &-wrapper {
      display: flex;
      align-items: center;
    }

    margin-left: calc(var(--spacing-unit-x4) / 3);
    border-left: var(--standard-border);
    padding: var(--spacing-unit) var(--spacing-unit-x3) var(--spacing-unit) var(--spacing-unit-x4);

    &-empty {
      padding-right: calc(var(--spacing-unit-x4) / 3);
    }

    &-loader {
      height: 2.6667rem;
      display: flex;
      align-items: center;
      width: 5.5833rem;
      border-left: var(--standard-border);
      margin-left: calc(var(--spacing-unit-x4) / 3);
      padding: 0 var(--spacing-unit-x4) 0 var(--spacing-unit-x4);
    }
  }

  &__landing-page-status {
    display: flex;
    align-items: center;
    margin-left: calc(var(--spacing-unit-x4) / 3);
    border-left: var(--standard-border);
    padding: var(--spacing-unit) var(--spacing-unit-x5) var(--spacing-unit) var(--spacing-unit-x2);
  }

  &__ab-test-status {
    &-wrapper {
      display: flex;
      align-items: center;
      padding: var(--spacing-unit) var(--spacing-unit-x3) var(--spacing-unit) var(--spacing-unit-x4);
      border-left: var(--standard-border);
    }
  }

  .navigation__logo {
    svg {
      margin-left: 0;
      max-height: var(--spacing-unit-x6);
    }
    width: unset;
    height: unset;
    position: unset;
  }
}
