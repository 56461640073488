.crm-string-field {
  &__input {
    flex-grow: 1;

    .tagify {
      max-width: unset;
    }

    &-with-helper {
      margin-bottom: var(--spacing-unit);
    }
  }
}
