.landing-page-templates-listing-page-container {
  &__create-template-button {
    height: var(--spacing-unit-x6);

    &-disabled {
      &.button:hover {
        cursor: not-allowed;
      }
    }

    .svg {
      fill: var(--white);
      margin-right: var(--spacing-unit);
    }
  }

  &__template-name {
    display: flex;
    align-items: center;

    &-typography {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }
  }

  &__template-buttons-wrapper {
    display: flex;
    align-self: center;
    gap: var(--spacing-unit-x2);
    height: var(--spacing-unit-x6);
  }

  &__content-catalog {
    max-height: var(--spacing-unit-x6);

    .listing-page-header__catalog-text {
      padding-bottom: 0;
    }
  }

  &__template-catalog-button {
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    white-space: nowrap;
  }

  .base-tag {
    max-width: var(--spacing-unit-x8);
  }
}
